import { StaticImageData } from "next/image"
import Link from "next/link"
import { memo } from "react"

import AiChatbot from "@spatialsys/assets/img/rebranding/use-case/ai-chatbot.webp"
import BrandExperience from "@spatialsys/assets/img/rebranding/use-case/brand-experience.webp"
import Education from "@spatialsys/assets/img/rebranding/use-case/education.webp"
import FashionShow from "@spatialsys/assets/img/rebranding/use-case/fashion-show.webp"
import InteractiveStorytelling from "@spatialsys/assets/img/rebranding/use-case/interactive-storytelling.webp"
import MultiplayerGame from "@spatialsys/assets/img/rebranding/use-case/multiplayer-game.webp"
import Presentation from "@spatialsys/assets/img/rebranding/use-case/presentation.webp"
import Tourism from "@spatialsys/assets/img/rebranding/use-case/tourism.webp"
import VirtualConcert from "@spatialsys/assets/img/rebranding/use-case/virtual-concert.webp"
import VirtualShowroom from "@spatialsys/assets/img/rebranding/use-case/virtual-showroom.webp"
import { Heading, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"

const USE_CASE_FIRST_ROW = [
  {
    title: "Multiplayer Game",
    krTitle: "멀티플레이어 게임",
    image: MultiplayerGame,
    ratio: 1.83,
    spaceUrl: "https://www.spatial.io/s/Neon-Ghost-65e2209a07789d42d8a8c56c?share=3616707370823690856",
  },
  {
    title: "Interactive Storytelling",
    krTitle: "인터랙티브 스토리텔링",
    image: InteractiveStorytelling,
    ratio: 1.66,
    spaceUrl: "https://www.spatial.io/s/Kasama-The-Awakening-664b0e020e022b348b50dc00?share=2048300244806952590",
  },
  {
    title: "Brand Experience",
    krTitle: "브랜드 경험",
    image: BrandExperience,
    ratio: 1.83,
    spaceUrl:
      "https://www.spatial.io/s/AbsolutLand-Journey-To-The-Cosmos-65fdd18031a103aec95ddfb1?share=3164056654901346524",
  },
  {
    title: "Education",
    krTitle: "교육",
    image: Education,
    ratio: 1.5,
  },
  {
    title: "AI Agents",
    krTitle: "AI 어시스턴트",
    image: AiChatbot,
    ratio: 1.5,
    spaceUrl: "https://www.spatial.io/s/Lofi-Chill-with-Ari-6684c84ebd196996e109587f?share=7166773969432973912",
  },
]

const USE_CASE_SECOND_ROW = [
  {
    title: "Fashion Show",
    krTitle: "패션쇼",
    image: FashionShow,
    ratio: 1.5,
    spaceUrl:
      "https://www.spatial.io/s/DFW-2023-Exclusible-x-Polycount-Web3-Couture-641e240e4f058f43042d7a20?share=9023020279693957239",
  },

  {
    title: "Presentation",
    krTitle: "프레젠테이션",
    image: Presentation,
    ratio: 1,
  },
  {
    title: "Virtual Showroom",
    krTitle: "버추얼 쇼룸",
    image: VirtualShowroom,
    ratio: 1.5,
    spaceUrl: "https://www.spatial.io/s/BOSS-Immersive-Showroom-641e08bd6127ed8eaff70faa?share=3302646144092575883",
  },
  {
    title: "Virtual Concert",
    krTitle: "버추얼 콘서트",
    image: VirtualConcert,
    ratio: 1.83,
    spaceUrl:
      "https://www.spatial.io/s/Phony-Ppl-Virtual-Concert-AB-L-The-Verse-65087653da9647a696ee5cc5?share=177595943486060059",
  },
  {
    title: "Tourism",
    krTitle: "여행",
    image: Tourism,
    ratio: 1.5,
    spaceUrl:
      "https://www.spatial.io/s/McDonalds-Lunar-New-Year-Experience-63bb3736f2513ed236ed3162?share=2851783860039212846",
  },
]

type UseCaseMarqueeProps = {
  isSmallScreen: boolean
}

export const UseCaseMarquee = memo(function UseCaseMarquee({ isSmallScreen }: UseCaseMarqueeProps) {
  return (
    <div className={cn("group flex flex-col", isSmallScreen ? "gap-2" : "gap-4")}>
      <UseCaseMarqueeRow isSmallScreen={isSmallScreen} rowItems={USE_CASE_FIRST_ROW} />
      <UseCaseMarqueeRow isSmallScreen={isSmallScreen} rowItems={USE_CASE_SECOND_ROW} isReverse />
    </div>
  )
})

type UseCaseMarqueeRowProps = {
  isSmallScreen: boolean
  rowItems: {
    title: string
    krTitle: string
    image: StaticImageData
    ratio: number
    spaceUrl?: string
  }[]
  isReverse?: boolean
}

export const UseCaseMarqueeRow = memo(function UseCaseMarqueeRow({
  isSmallScreen,
  rowItems,
  isReverse,
}: UseCaseMarqueeRowProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"

  return (
    <div className="relative flex h-[171px] w-[100vw] max-w-full items-center overflow-hidden sm:h-[243px]">
      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 w-32 bg-gradient-to-r from-background via-background/80 via-30% to-transparent sm:w-48" />
      <div className="absolute flex h-[168px] w-max sm:h-[240px]">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            aria-hidden={index > 0}
            className={cn(
              isReverse ? "animate-marquee-reverse" : "animate-marquee",
              "duration-3500 sm:duration-4000 group-hover:paused"
            )}
          >
            {rowItems.map(({ title, krTitle, image, ratio, spaceUrl }) => (
              <Link
                key={`${title}-${index}`}
                href={spaceUrl ?? "https://www.spatial.io/create"}
                target="_blank"
                className="group/item relative mx-1 inline-block h-[168px] overflow-hidden rounded-3xl shadow-[0_0_0_1.5px_rgba(255,255,255,0.15)] sm:mx-2 sm:h-[240px]"
                style={{ width: isSmallScreen ? `${Math.round(168 * ratio)}px` : `${Math.round(240 * ratio)}px` }}
              >
                <img
                  alt={title}
                  src={image.src}
                  loading="lazy"
                  className="size-full object-cover transition-transform duration-500 group-hover/item:scale-110"
                />
                <div className="absolute bottom-0 h-[70px] w-full bg-gradient-to-t from-[#111] from-[6.46%] to-transparent sm:h-[100px]" />
                <Heading
                  className={cn("absolute bottom-3 left-6 text-sm sm:text-[20px]", isKorean ? "font-kr" : undefined)}
                >
                  {isKorean ? krTitle : title}
                </Heading>
              </Link>
            ))}
          </div>
        ))}
      </div>
      <div className="pointer-events-none absolute inset-y-0 right-0 z-10 w-32 bg-gradient-to-l from-background via-background/80 via-30% to-transparent sm:w-48" />
    </div>
  )
})
