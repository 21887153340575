import { memo, useCallback } from "react"

import { useAppContext } from "@spatialsys/web/app-context"
import { Button, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"
import { UseCaseMarquee } from "./use-case-marquee"

type UseCaseProps = {
  isSmallScreen: boolean
}

export const UseCase = memo(function UseCase({ isSmallScreen }: UseCaseProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"

  const actions = useAppContext((context) => context.actions)
  const showOldHomepage = useCallback(() => {
    actions.showOldHomepage()
  }, [actions])

  return (
    <div className={cn("z-10 bg-background", isSmallScreen ? "pb-[48px] pt-[72px]" : "pb-[72px] pt-[120px]")}>
      <SectionHeader
        title={isKorean ? "스페이셜 활용 사례" : "Use Cases"}
        titleClassName={isKorean ? "font-kr font-bold" : undefined}
        catchphrase={isKorean ? "수백만 개의 인터랙티브 스토리가 모이는 곳" : "Powering Millions of Interactive Worlds"}
        catchphraseClassName={isKorean ? "font-kr" : undefined}
        description={
          isKorean ? (
            <>
              1500만 명 이상의 크리에이터 및 브랜드들의 성공적인 스페이셜 활용법을 둘러보세요.
              <br />
              스페이셜에서는 300만 개 이상의 몰입형 경험들이 UGC, 기업 솔루션, 가상 학습, 차세대 미디어 등을 아우르며
              미래를 열어갑니다.
            </>
          ) : (
            <>
              Join 15M+ creators and brands shaping the future with 3M+ immersive experiences powered by Spatial
              <br />
              —spanning UGC, enterprise solutions, virtual learning, and next-gen media.
            </>
          )
        }
        descriptionClassName={isKorean ? "font-kr" : undefined}
      />
      <div className="mx-auto mt-14 w-full max-w-screen-4xl">
        <UseCaseMarquee isSmallScreen={isSmallScreen} />
      </div>
      <div className="mt-8 grid w-full px-4 sm:mt-14">
        <Button
          size={isSmallScreen ? "lg" : "xl"}
          className={cn(
            "relative w-full justify-self-center overflow-hidden bg-gradient-to-r from-[#00CCFF] to-[#0033FF] text-sm text-white sm:max-w-[480px] sm:text-lg",
            isKorean && "font-kr font-bold"
          )}
          onClick={showOldHomepage}
          noScaleOnHover={isSmallScreen}
        >
          <span className="pointer-events-none z-10">{isKorean ? "더 알아보기" : "Explore more"}</span>
          <div className="absolute right-0 top-0 size-full bg-[#0044ff] opacity-0 transition-opacity duration-500 hover:opacity-100" />
        </Button>
      </div>
    </div>
  )
})
